import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";

export default function Footer() {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '36px 16px',
            alignItems: 'center',
        }}>
            <Stack
                direction="row"
                spacing="8px"
            >
                <IconButton 
                    href="https://www.facebook.com/ChurchMorningStar/"
                    target="_blank"
                >
                    <Box
                        component="img"
                        src="/static/images/facebook-icon-24x24.png"
                        
                        sx={{height:"24px"}}
                    />
                </IconButton>
                <IconButton
                    href="https://www.youtube.com/channel/UC0XTaew0w9ZQp9oz7UMkgtA"
                    target="_blank"
                >
                    <Box
                        component="img"
                        src="/static/images/youtube-icon-24x24.png"
                        
                        sx={{height:"24px"}}
                    />
                </IconButton>
            </Stack>
            <Typography
                variant="caption"
                color="text.secondary"
                sx={{mt: 1}}
            >
                © Ранкова Зірка 2011 - 2023
            </Typography>
        </Box>
    )
}