import React from "react";

import {
    Box,
    Typography,
    CardMedia,
    Container,
    Button,
    Stack
} from '@mui/material'

export default function SectionCard(props) {
    const headline = props.headline;
    const buttonName = props.buttonName;
    const buttonLink = props.buttonLink;
    const body = props.body;
    const image = props.image;

    return (
        <Container 
            maxWidth="sm"
            disableGutters 
            sx={{
                p: "0px 16px",
            }}
        >
            <Stack gap='16px'>
            <Box
                sx={{
                    textAlign: 'center',
                }}
            >
                <Typography variant="h2">
                    {headline}
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                    {body}
                </Typography>
                {
                    buttonName &&

                    <Box
                        sx={{
                            display: 'flex',
                            minWidth: '60px',
                            justifyContent: 'center'
                        }}
                    >
                        <Button 
                            variant="outlined"
                            color="secondary"
                            href={buttonLink}
                            target="_blank"
                        >
                            {buttonName}
                        </Button>
                    </Box>   
                }
                                
            </Box>
            { 
                props.component 
            }

            {
                image &&

                <CardMedia 
                    component='img'
                    src={image}
                    height="280px"
                />

            }
            </Stack>
        </Container>
    )
}