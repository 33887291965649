import React from "react";

import {
    CardMedia,
    Paper,
    Typography,
    Box
} from '@mui/material'

export default function VideoCard() {

    return (
        <Paper square sx={{
            position: "relative",
        }}>
            <CardMedia
                component="video"
                src="/static/videos/Video-presentation-rankova-zirka-cerkva.mp4"
                height="auto"
                autoPlay
                loop
                muted
                playsInline
                sx={{
                    objectFit: 'cover',
                    minHeight: '80vh'
                }}
            />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: "100%",
                textAlign: "center",
                justifyContent: "center",
            }}>
                <Typography variant="h1">
                    Ранкова Зірка
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    Християнська церква м. Вінниця
                </Typography>
            </Box>
        </Paper>
            
    )
}