import React from "react";

import { Box, CardMedia, Typography, Card } from "@mui/material";

export default function SwipeableCardElevated(props) {
    const title = props.title;
    const caption = props.caption;
    const body = props.body;
    const image = props.image;

    return (
        <Card
            sx={{height: '100%'}}
            elevation={3}
        >
            <CardMedia 
                component='img'
                src={image}
                height='280px'
            />
            <Box 
                sx={{
                    padding: '8px'
                }}
            >
                <Typography variant="caption" color="text.disabled">
                    {caption}
                </Typography>
                <Typography variant="h3">
                    {title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    {body}
                </Typography>
            </Box>
        </Card>
    )
}