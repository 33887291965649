import React from "react";
import {
    AppBar,
    Toolbar,
    Box,
    useScrollTrigger
} from "@mui/material";

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 1 : 0,
      color: trigger ? "" : "transparent",
    });
  }
  

export default function TopAppBar(props) {

    return (
        <ElevationScroll {...props}>
            <AppBar 
                sx={{ 
                    opacity: 0.97,
                }}
            >
                <Toolbar sx={{display: "flex"}}>
                <Box
                    component="img"
                    src="/static/images/rankova-zirka-logo.svg"
                    
                    sx={{height:"32px"}}
                />
                </Toolbar>
            </AppBar>

        </ElevationScroll>
    )
}