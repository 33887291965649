export const services = {
    headline: 'Приходьте на служіння',
    subheadline: 'Відчуйте спокій та радість від Бога разом з нами',
    events: [
        {
            label:"Недільне",
            caption:"НЕДІЛЯ 11:00",
            image:"/static/images/nedilna-sluzhinnia.jpg",
            title:"Недільне Богослужіння",
            body:"Загальне зібрання. Прославлення, поклоніння, молитва та проповідь.",
        },
        {
            label:"Молитовне",
            caption:"СЕРЕДА 18:00",
            image:"/static/images/molytovne-sluzhinnia.jpg",
            title:"Молитовне Богослужіння",
            body:"Молімося за Україну разом! Хвала, поклоніння та провідь.",
        },
        {
            label:"Молодіжне",
            caption:"СУБОТА 17:00",
            image:"/static/images/molodizhne-sluzhinnia.jpg",
            title:"Молодіжне Богослужіння",
            body:"Жива музика, спілкування, духовне послання для молоді, кавабрейк.",
        }
    ]
}

export const spiritualProjects = {
    headline: 'Зростаємо духовно',
    subheadline: '«Не уподібнюйтеся світові цьому, а змінюйтесь оновленням вашого розуму...» Римлянам 12:2',
    projects: [
        {
            image:"/static/images/spiritual-projects/obkladynka-domashnioi-cerkvy.jpg",
            caption:"ВІВТОРОК АБО ЧЕТВЕР",
            title:"Домашні церкви",
            body:"Час в затишній домашній атмосфері, який ми проводимо у спілкуванні. Обговорюємо проповідь, занурюємося в Писання, молимось та дізнаємось більше одне про одного.",
        },
        {
            image:"/static/images/spiritual-projects/obkladunka-X-Change.jpg",
            caption:"ІНДИВІДУАЛЬНО",
            title:"Учнівство X-change",
            body:"Це програма обміну де наставник передає свої знання учню. X-change допоможе тобі зміцнити відносини з Богом, глибше пізнати Його. Стати частиною церкви та ввійти у своє покликання.",
        },
        {
            image:"/static/images/spiritual-projects/obkladynka-rankovoi-molytvy.jpg",
            caption:"ПН-ПТ 08:00",
            title:"Ранкові молитви",
            body:"Щоранку ми об’єднуємось у спільній молитві за Україну, церкву та потреби кожного з нас.",
        },
        {
            image:"/static/images/spiritual-projects/obkladynka-nedilnoi-shkoly.jpg",
            caption:"НЕДІЛЯ 11:00",
            title:"Недільна школа",
            body:"Дітки по особливому мають пізнавати Бога. Тут вони вчаться на Біблійних історіях, весело проводять час та розвиваються. Уроки проходять щонеділі об 11:00 для дітей віком 3-12 років.",
        },
        {
            image:"/static/images/spiritual-projects/obkladynka-biblijnogo-kursu.jpg",
            caption:"ЧЕТВЕР 17:30",
            title:"Біблійний курс",
            body:"Віра в Бога неможлива без знайомства з самим Богом. Він написав для нас Біблію, де розповів про Себе все. Ви можете ознайомитися з цією книгою в форматі коротких занять.",
        },
    ]
}

export const projectsDuringWar = {
    headline: 'Наближаємо перемогу',
    subheadline: '«З Богом ми покажемо силу, і Він подолає гнобителів наших.» Псалом 59:14',
    projects: [
        {
            image:"static/images/projects-during-war/obkladunka-volontery-na-peredovij.jpg",
            title:"Капеланське служіння",
            body:"Духовна та гуманітарна допомога українським військовим та цивільним у зоні бойових дій.",
        },
        {
            image:"static/images/projects-during-war/obkladunka-humanitarnyi-punkt.jpg",
            title:"Гуманітарний пункт",
            body:"Пункт надання гуманітарної допомоги ВПО у Вінниці.",
        },
        {
            image:"static/images/projects-during-war/obkladunka-vidbudova-ridnoi-zemli.jpg",
            title:"Відбудова рідної землі",
            body:"Благодійна відбудова зруйнованих в результаті війни будинків на Миколаївщині.",
        },
        {
            image:"static/images/projects-during-war/obkladunka-majsternia-dobra.jpg",
            title:"Майстерня добра",
            body:"Креативний простір для внутрішньо переміщених дітей у Вінниці.",
        },
    ]
}

export const socialProjects = {
    headline: 'Впливаємо соціально',
    subheadline: '«Ви — світло світу. Не може сховатися місто, яке стоїть на верху гори.» Мф 5:14',
    projects: [
        {
            image:"static/images/social-projects/obkladunka-dim-slavy.jpg",
            title:"Будівництво Дому Слави",
            body:"Будівництво центру культурного розвитку та підтримки нужденних у Вінниці.",
        },

        {
            image:"static/images/social-projects/obkladunka-dim-matery-ta-dutunu.jpg",
            title:"Дім матері та дитини",
            body:"Допомога матерям з дітьми, що опинилися в складних життєвих обставинах.",
        },
        {
            image:"static/images/social-projects/obkladunka-dopomojemo-vudjyty.jpg",
            title:"Годування",
            body:"Допомога людям готовими харчами на вулиці.",
        },
        {
            image:"static/images/social-projects/obkladunka-sozyalne-patrylyvanna.jpg",
            title:"Соціальне патрулювання",
            body:"Допомога безхатченкам у Вінниці.",
        },
        {
            image:"static/images/social-projects/obkladunka-dopomoga-bezdomnum.jpg",
            title:"Пункти допомоги безхатченкам",
            body:"Допомога безхатченкам харчами, гуманітарно та духовно.",
        },
    ]
}

export const adaptationProjects = {
    headline: 'Адаптуємо залежних',
    subheadline: '«Рятуй узятих на смерть, і невже відмовишся від приречених на убиття?» Притч 24:11',
    projects: [
        {
            image:"static/images/social-projects/obkladunka-centry-adaptacii.jpg",
            title:"Жіночий центр адаптації",
            body:"Допомога жінкам з алко- та нарко- залежністю адаптуватись до вільного життя.",
        },
        {
            image:"static/images/social-projects/obkladynka-cholovichiy-centr-adaptacii.jpg",
            title:"Чоловічий центр адаптації",
            body:"Допомога чоловікам з алко- та нарко- залежністю адаптуватись до вільного життя.",
        },
        {
            image:"static/images/social-projects/obkladunka-coffee-house.jpg",
            title:"Кофе-хауз",
            body:"Група підтримки, для людей, які потрапили в складні життєві обставини та потребують звільнення від залежностей.",
        },
    ]
}

export const pastors = {
    headline: 'Пастори',
    subheadline: '«І дам вам пастирів за серцем Моїм, які будуть пасти вас зі знанням і розсуд­ли­вістю.» Єр 3:15',
    pastors: [
    {
        title: 'Олександр Парфенюк',
        body: 'Старший пастор',
        image: '/static/images/pastors/pastor-oleksandr.jpg',
        facebookLink: 'https://www.facebook.com/alexandr.parfenyk',
        instagramLink: ''
    },
    {
        title: 'Анатолій Загляда',
        body: 'Пастор',
        image: '/static/images/pastors/pastor-anatolii.jpg',
        facebookLink: 'https://www.facebook.com/tolik.zaglyada',
        instagramLink: 'https://www.instagram.com/zaglyada_fj/'
    }
]}