import { 
    Box, 
    Typography,
    Tabs,
    Tab,
    Container
} from "@mui/material";
import SwipeableViews from 'react-swipeable-views';
import React, { useState } from "react";
import { services } from '../content';

import SwipeableCard from "../components/SwipeableCard";

export default function InvitationToService() {
    const [value, setValue] = useState(0) 

    const headline = services.headline;
    const subheadline = services.subheadline;
    const cards = services.events;


    function handleChangeTab(event,newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }

    return (
        <Container 
            maxWidth="sm"
            disableGutters 
            sx={{
                p: "0px 16px"
            }}
        >
            <Box sx={{
                textAlign: "center",
            }}>
                <Typography variant="h2">
                    {headline}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    {subheadline}
                </Typography>
            </Box>
            <Box sx={{pb: "16px"}}>
                <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="Наші служіння"
                >
                    {cards.map(card => 
                            <Tab label={card.label} />
                        )}
                </Tabs>
            </Box>
            <Box>
                <SwipeableViews
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    enableMouseEvents
                >
                    {
                        cards.map((card, index) => 
                                <SwipeableCard 
                                    key={index}
                                    image={card.image}
                                    caption={card.caption}
                                    title={card.title}
                                    body={card.body}
                                />
                            )
                    }
                </SwipeableViews>
            </Box>
        </Container>
    )
}