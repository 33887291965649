import React from "react";

import GoogleMapReact from 'google-map-react';

import LocationOnIcon from '@mui/icons-material/LocationOn';

function Marker() {

    return (
        <LocationOnIcon
            fontSize="large"
            color="primary"
        />
    )
}

export default function SimpleMap(){
    const defaultProps = {
      center: {
        lat: 49.23984896093923,
        lng: 28.48080456930068
      },
      zoom: 15
    };
  
    return (
      <div style={{ height: '280px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBAnBhoydKCtgSHGW5sdFKwPQ0CXXNkXQ8" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
                <Marker
                    lat={49.23984896093923}
                    lng={28.48080456930068}
                />
            
        </GoogleMapReact>
      </div>
    );
  }
  