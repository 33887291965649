import { Container, Typography, Box, Stack } from "@mui/material";
import SwipeableCardElevated from "./SwipeableCardElevated";
import SwipeableViews from 'react-swipeable-views';

export default function ContainerWithCards({headline, subheadline, cards}) {
  
    return (
      <Container 
        maxWidth="sm"
        disableGutters 
      >
        <Box
          sx={{
            p: "16px",
            textAlign: 'center',
          }}
        >
          <Typography variant="h2">
            {headline}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            {subheadline}
          </Typography>
        </Box>
        <Box>
        <SwipeableViews slideStyle={{padding: '0 8px'}} style={{padding: '0 16px'}} enableMouseEvents>
        {
          cards.map(
              card => 
              <SwipeableCardElevated
                label={card.label}
                caption={card.caption}
                image={card.image}
                title={card.title}
                body={card.body}
              />
            )
        }
        </SwipeableViews>
        </Box>
      </Container>
    )
  }