import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#000000',
        paper: '#000000',
      },
      secondary: {
        main: '#FFFFFF'
      },
      primary: {
        main: '#000000'
      }
    },
    typography: {
      h1: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        fontSize: '80px'
      },
      h2: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        fontSize: '42px'
      },
      h3: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        fontSize: '22px'
      },
      h4: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
      },
      body1: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        fontSize: '16px'
      },
      subtitle1: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        fontSize: '20px'
      },
      subtitle2: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        fontSize: '18px'
      },
      caption: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        fontSize: '12px'
      },
      button: {
        fontFamily: [
          'Montserrat',
          'sans-serif',
        ].join(','),
        textTransform: "none"
      },
    },
  });
theme = responsiveFontSizes(theme, {factor: 3});

export { theme };