import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";

export default function SwipeableCard(props) {
    const title = props.title;
    const caption = props.caption;
    const body = props.body;
    const image = props.image;

    return (
        <Box>
            <CardMedia 
                component='img'
                src={image}
                height="240px"
            />
            <Typography variant="caption" color="text.disabled">
                {caption}
            </Typography>
            <Typography variant="h3">
                {title}
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {body}
            </Typography>
        </Box>
    )
}