import { Stack, Box, Container, Typography, Card, CardMedia, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

export default function ContainerWithSmallCards({headline, subheadline, cards}) {
    return (
      <Container
        maxWidth="sm"
        disableGutters 
      >
        <Box
            sx={{
              p: "16px",
              textAlign: 'center',
            }}
          >
            <Typography variant="h2">
              {headline}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {subheadline}
            </Typography>
          </Box>
          <Stack gap={2} sx={{pl: '16px', pr: '16px'}}>
          {
            cards.map((card) => 
            <Card
              sx={{display: 'flex', maxHeight: '200px'}}
              elevation={3}
          >
              <CardMedia 
                  component='img'
                  src={card.image}
                  sx={{width: '50%'}}
              />
              <Stack 
                  sx={{
                      width: '50%',
                  }}
              >
                <Box sx={{padding: '8px',}}>
                  <Typography variant="h3">
                      {card.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                      {card.body}
                  </Typography>
                </Box>
                <Stack direction='row' gap={1} ml={1}>
                    {
                        card.facebookLink && 
                        <IconButton
                            href={card.facebookLink}
                            target="_blank"
                        >
                            <FacebookIcon />
                        </IconButton>    
                    }
                    {
                        card.instagramLink &&
                        <IconButton 
                            href={card.instagramLink}
                            target="_blank"
                        >
                            <InstagramIcon />
                        </IconButton>
                    }
                </Stack>
              </Stack>
          </Card>
            )
          }   
          </Stack>
          
      </Container>
    )
  }