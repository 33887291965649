import React from "react";

import { spiritualProjects, projectsDuringWar, socialProjects, adaptationProjects, pastors } from './content';
import { Stack } from '@mui/material';

import VideoCard from "./components/VideoCard";
import TopAppBar from "./components/TopAppBar";
import InvitationToService from "./InvitationToService";
import SectionCard from "./components/SectionCard";
import GoogleMap from "./components/GoogleMap";
import Footer from "./components/Footer";
import ContainerWithCards from "./components/ContainerWithCards";
import ContainerWithSmallCards from "./components/ContainerWithSmallCards";

function App() {

  return (
    <>
      <TopAppBar />
      <Stack gap={12}>
      <VideoCard />
      <InvitationToService />
      <ContainerWithCards
        headline={spiritualProjects.headline}
        subheadline={spiritualProjects.subheadline}
        cards={spiritualProjects.projects}
      />
      <ContainerWithCards
        headline={projectsDuringWar.headline}
        subheadline={projectsDuringWar.subheadline}
        cards={projectsDuringWar.projects}
      />
      <ContainerWithCards
        headline={socialProjects.headline}
        subheadline={socialProjects.subheadline}
        cards={socialProjects.projects}
      />
      <ContainerWithCards
        headline={adaptationProjects.headline}
        subheadline={adaptationProjects.subheadline}
        cards={adaptationProjects.projects}
      />
      <ContainerWithSmallCards
        headline={pastors.headline}
        subheadline={pastors.subheadline}
        cards={pastors.pastors}
      />
      <SectionCard
        image="/static/images/watch-youtube.jpg"
        caption="YOUTUBE"
        headline="Дивіться онлайн"
        body="Служіння, проповіді, прославлення та інша діяльність церкви доступні на YouTube"
        buttonLink="https://www.youtube.com/channel/UC0XTaew0w9ZQp9oz7UMkgtA"
        buttonName="Перейти на канал"
      />
      <SectionCard
        buttonName="Відкрити в Google Карті"
        buttonLink="https://goo.gl/maps/hgMLt4MnfNQkpM8t8"
        caption="ВІННИЦЯ"
        headline="Адреса"
        body="м. Вінниця, вул. Стеценка, 61"
        component={<GoogleMap />}
      />
      <Footer />
      </Stack>
    </>
  );
}

export default App;